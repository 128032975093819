@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 392px);
    gap: 18px;
    justify-content: center;
}

.card {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    overflow: hidden;

    .img_container {
        height: 470px;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        color: $black;
        width: 100%;
        padding: 24px;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: $white;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        p:last-of-type {
            margin: 0;
        }
    }

    &:hover {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $red;
        }
    }

}



@media only screen and (max-width: 1220px) {
    .section_container {
        grid-template-columns: repeat(2, 392px);
    }
}



@media only screen and (max-width: 900px) {
    .section_container {
        padding: $section-padding-mobile;
        grid-template-columns: 1fr;
        gap: 52px;
    }

    .card {
        .img_container {
            max-height: 400px;
        }

        .text_container {
            padding: 12px;
        }
    }
}