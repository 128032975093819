.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 24px;

    h3 {
        margin: 0;
    }
}


@media only screen and (max-width: 900px) {
    .container {
        width: 100%;
        margin-top: 18px;

        h3 {
            padding: 0 6px;
        }
    }
}
