@use '../../utils/styles/variables' as *;

.section_container {
    padding: $section-padding-web;
    width: 100%;
    display: flex;
    justify-content: center;
}

.text_container {
    max-width: 640px;
    width: 100%;
    padding: 0 8px;

    .text {
        width: 100%;

        p:last-of-type {
            margin-bottom: 0;
        }

        img {
            max-height: 520px;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}


@media only screen and (max-width: 900px) {
    .section_container {
        padding: $section-padding-mobile;
    }
}