.App {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content {
  margin-top: 76px;
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}
