@use '../../utils/styles/variables' as *;

.container {
    width: 100%;
    background-color: $gray-dark;
    color: $white;
    padding: 16px 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    p {
        text-transform: uppercase;
        position: relative;
        margin-bottom: 0px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: -1px;
            bottom: 0;
            margin: auto 0;
            height: 1px;
            width: 20px;
            background-color: $white;
        }

        &::before {
            left: -28px;
        }

        &::after {
            right: -28px;
        }

    }

    a {
        max-width: 200px;
        width: 100%;
        margin-top: 12px;

        &:hover {
            filter: brightness(0.8);
        }
    }
}


@media only screen and (max-width: 900px) {
    .container {
        p {

            &::before,
            &::after {
                width: 14px;
            }

            &::before {
                left: -20px;
            }

            &::after {
                right: -20px;
            }
        }
    }
}