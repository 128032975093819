@use './../../utils/styles/variables' as *;

.section_container {
    padding-bottom: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        width: 100%;
        max-width: 1200px;
    }
}


.card_container {
    width: 100%;
    height: 490px;
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    isolation: isolate;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    overflow: hidden;

    .img_container {
        height: 100%;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        z-index: 2;
        color: $black;
        min-width: 352px;
        max-width: 492px;
        height: 100%;
        padding: 24px;
        background-color: $white;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        p:last-of-type {
            margin: 0;
        }
    }

    &:hover {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $red;
        }
    }

}


@media only screen and (max-width: 900px) {
    .section_container {
        margin-bottom: 24px;
    }

    .card_container {
        height: 644px;
        flex-direction: column-reverse;

        .text_container {
            padding: 12px;
            height: fit-content;
            max-width: 100%;
            width: 100%;
        }
    }
}