@use '../../utils/styles/variables' as *;

.container {
    width: 100%;
    max-width: 1200px;
    padding: $section-padding-web;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 18px;
}


.text_container {
    width: 100%;
    height: 100%;

    *:last-child {
        margin-bottom: 0;
    }
}


.img_column {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 18px
}


.img_container {
    min-height: 200px;
    max-height: 500px;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


@media only screen and (max-width: 900px) {
    .container {
        padding: $section-padding-mobile;
        flex-direction: column-reverse;
    }

    
    .img_column {
        width: 100%;
    }
}