@use '../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    max-width: 640px;
    width: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .img {
        height: 142px;
        width: 142px;
        margin-bottom: 18px;
        border-radius: 50%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    p {
        margin: 0;
    }

    a {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            height: 1.4rem;
            width: 1.3rem;
            transition: $default-transition;
            background-image: url(../../../public/images/mail-icon.svg);
            background-repeat: no-repeat;
            background-size: 1.1rem;
            background-position-y: center;
            opacity: 1;
        }

        &:hover {
            &::before {
                opacity: 0.6;
            }
        }
    }
}


@media only screen and (max-width: 900px) {
    .section_container {
        padding-bottom: 28px;
    }
}