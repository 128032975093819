@use '../../utils/styles/variables' as *;

.container {
    min-height: 150px;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.card {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;

    .img_container {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        font-family: 'Playfair Display', sans-serif;
        color: $white;
        text-align: center;
        background-color: $black-opacity;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        padding: 6px;

        p {
            margin: 0;
        }
    }
}


@media only screen and (max-width: 900px) {
 
}