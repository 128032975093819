@use '../../utils/styles/variables' as *;

.container {
    width: 100%;
    padding: $section-padding-web;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px;
}


.card {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img_container {
        height: 210px;
        width: 210px;
        border-radius: 50%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        width: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p:last-of-type {
            margin: 0;
        }
    }
}


@media only screen and (max-width: 900px) {
    .container {
        padding: $section-padding-mobile;
        gap: 52px;
    }

    .card {
        width: 100%;

        .text_container {
            padding: 12px;
        }
    }
}