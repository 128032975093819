@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    gap: 18px;
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    background-color: $white;
    overflow: hidden;
    padding: 24px;
    font-family: 'Playfair Display', sans-serif;

    p:last-of-type {
        margin: 0;
    }
}


@media only screen and (max-width: 900px) {
    .section_container {
        padding: $section-padding-mobile;
    }

    .card {
        padding: 12px;
    }
}