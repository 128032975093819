@use '../../utils/styles/variables' as *;

.footer {
    flex-shrink: 0;
    min-height: 200px;
    margin-top: 24px;
    width: 100%;
    color: $white;
    background-color: $black;
    padding: 0 18px;
    display: flex;
    justify-content: center;

    .constraints {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        align-self: center;
        max-width: 1200px;
        width: 100%;
        height: 100%;

        a {
            color: $white;
        }

        a:hover {
            color: $gray-light;
        }

        .logo {
            max-width: 508px;
            position: relative;

            img {
                width: 100%;
                object-fit: contain;
            }

            .meplus {
                position: absolute;
                height: 48px;
                width: 48px;
                right: -62px;
                top: 7px;
                z-index: 2;
            }
        }

        .links {
            display: flex;
            flex-direction: column;
            align-self: center;

            a {
                margin-bottom: 4px;
            }

            .solhemmet {
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    display: inline-block;
                    vertical-align: bottom;
                    height: 1.4rem;
                    width: 1.3rem;
                    // padding-right: 4px;
                    transition: $default-transition;
                    background-image: url(../../../public/images/sun-logo-icon.svg);
                    background-repeat: no-repeat;
                    background-size: 1.1rem;
                    background-position-y: center;
                }
            }
        }



    }
}


@media only screen and (max-width: 900px) {
    .footer {
        padding: 12px 4px;
        padding-bottom: 24px;

        .constraints {
            flex-direction: column;

            .logo {
                margin-top: 12px;
                margin-bottom: 28px;
                margin-right: 48px;
                padding: 6px;

                .meplus {
                    transform: scale(0.8);
                    right: -46px;
                }
            }
        }
    }
}