@use './../../utils/styles/variables' as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.articles {
    max-width: 1200px;
    width: 100%;
    padding: $section-padding-web;
}

@media only screen and (max-width: 900px) {
    .container {
        margin-top: 28px;
    }

    .articles {
        padding: 28px 0px;
    }
}