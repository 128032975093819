@use '../../utils/styles/variables' as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.preferences_container {
    padding: $section-padding-web;
    max-width: 640px;
    width: 100%;
    padding: 0 8px;

    form {
        display: flex;
        flex-direction: row;

        label {
            margin: 0;
            cursor: pointer;
        }

        input {
            margin-right: 8px;
        }
    }
}


@media only screen and (max-width: 900px) { 
    .preferences_container {
        padding: $section-padding-mobile;
    }
}