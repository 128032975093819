@use '../../utils/styles/variables' as *;

.section_container {
    padding: $section-padding-web;
    padding-bottom: 0;
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.video_container {
    width: 100%;
    height: 100%;
}

.video {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
}


@media only screen and (max-width: 900px) {
    .section_container {
        padding: $section-padding-mobile;
        padding-bottom: 0;
        gap: 18px;
    }
}