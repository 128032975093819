@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    width: 100%;
    height: 78vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: $gradient-bottom-to-top;
        z-index: 2;
    }

    .img_container {
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: 1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: $white;
        padding: 6px;
        padding-bottom: 68px;
        width: 70%;

        .button {
            color: $white;
            margin-top: 24px;
        }
    }
}



@media only screen and (max-width: 900px) {
    // .section_container {
    // }

    .card {

        .text_container {
            width: 100%;
        }
    }
}