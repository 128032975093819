@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $section-padding-web;
    padding-bottom: 0;
}

.card {
    width: 100%;
    height: 100%;
    min-height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    overflow: hidden;

    .img_container {
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: 1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     inset: 0;
        //     background-color: $black-opacity;
        // }
    }

    .text_container {
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background: $gradient-bottom-to-top;

        .text {
            color: $white;
            text-align: center;
            width: 80%;
            padding: 8px;
            padding-bottom: 0;
            margin-bottom: 26px;
        }
    }
}



@media only screen and (max-width: 900px) {
    .section_container {
        padding: $section-padding-mobile;
        padding-bottom: 0;
    }

    .card {
        min-height: 400px;

        .text_container {
            .text {
                width: 100%;
            }
        }
    }
}