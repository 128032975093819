// colors
$red: #E71650;

$blue: #00AFBE;
$blue-light: #93CBD2;
$blue-dark: #2B74A1;

$black: #222222;
$black-opacity: rgba(34, 34, 34, 0.8);

$gray-light: #E6E9EA;
$gray-dark: #3D4142;

$white: #FFFFFF;
$white-opacity: rgba(252, 252, 252, 0.8);

$off-white: #FCFCFC;

// defaults
$default-transition: all 0.2s ease-out;
$default-opacity: 0.8;
$default-box-shadow: 4px 4px 6px rgba(34, 34, 34, 0.8);
$default-edge-padding: 0px 6px;
$section-padding-web: 64px 0px;
$section-padding-mobile: 28px 6px;
$border-radius: 8px;


// gradients
$gradient-bottom-to-top: linear-gradient(to top,
        hsl(0, 0%, 13%) 0%,
        hsla(0, 0%, 13%, 0.918) 3.4%,
        hsla(0, 0%, 13%, 0.832) 6.5%,
        hsla(0, 0%, 13%, 0.744) 9.5%,
        hsla(0, 0%, 13%, 0.656) 12.6%,
        hsla(0, 0%, 13%, 0.567) 15.9%,
        hsla(0, 0%, 13%, 0.481) 19.6%,
        hsla(0, 0%, 13%, 0.398) 24%,
        hsla(0, 0%, 13%, 0.319) 29%,
        hsla(0, 0%, 13%, 0.245) 35%,
        hsla(0, 0%, 13%, 0.179) 42.1%,
        hsla(0, 0%, 13%, 0.121) 50.4%,
        hsla(0, 0%, 13%, 0.073) 60.2%,
        hsla(0, 0%, 13%, 0.036) 71.6%,
        hsla(0, 0%, 13%, 0.011) 84.8%,
        hsla(0, 0%, 13%, 0) 100%);