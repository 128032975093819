@use './../../utils/styles/variables' as *;

.container1 {
    height: 100%;
    width: 100%;
    transition: $default-transition;

    .container2 {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: spinShadow 10s infinite linear;
        isolation: isolate;

        .plus,
        .shadow {
            position: absolute;
            height: 100%;
            width: 100%;
            transition: $default-transition;
        }

        .plus {
            z-index: 2;
            transform: rotate(-0deg) translate(-4px, -4px) rotate(0deg);
            animation: spinPlus 10s infinite linear;
        }

        .shadow {
            z-index: 1;
            filter: blur(1px);
            opacity: 0;

            rect {
                fill: $black-opacity;
            }
        }

        &:hover {
            .shadow {
                filter: blur(6px);
                opacity: 0.8;
            }
        }

        &:active {
            .shadow {
                filter: blur(7px);
                opacity: 0.7;
                height: 102%;
                width: 102%;
            }
        }
    }

    &:hover {
        transform: translate(-4px, -4px);
    }

    &:active {
        transform: translate(-10px, -10px) scale(1.04);
    }
}


@keyframes spinShadow {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spinPlus {
    to {
        transform: rotate(-360deg) translate(-4px, -4px) rotate(360deg);
    }
}