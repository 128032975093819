@use '../../utils/styles/variables' as *;

.container {
    background-color: $white;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    max-width: 640px;
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


@media only screen and (max-width: 900px) { 
    .container {
        padding: 12px 0;
    }
}
