@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: $section-padding-web;
    gap: 18px;
}

.card {
    width: 100%;
    max-width: 500px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    overflow: hidden;
    padding: 24px;
    background-color: $white;

    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .img_container {
            height: 142px;
            width: 142px;
            margin-right: 18px;
            margin-bottom: 18px;
            border-radius: 50%;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }


    .text_container {

        p:last-of-type {
            margin: 0;
        }
    }
}



@media only screen and (max-width: 900px) {
    .section_container {
        padding: $section-padding-mobile;
        flex-direction: column;
        gap: 52px;
    }

    .card {
        height: fit-content;
        max-width: 100%;
        padding: 12px;

        .top {

            .img_container {
                height: 102px;
                width: 102px;
            }
        }
    }
}