@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    max-width: 1200px;
    padding: $section-padding-web;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 18px;

    a:hover {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $red;
        }
    }
}

.tall_card {
    max-width: 580px;
    // max-height: 1100px; // Maybe not wanted
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    overflow: hidden;

    .img_container {
        height: 100%;
        width: 100%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        color: $black;
        width: 100%;
        padding: 24px;
        background-color: $white;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        p:last-of-type {
            margin: 0;
        }
    }
}


.small_cards {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    max-width: 620px;
}


.card {
    width: 100%;
    max-height: 590px;
    min-height: 290px;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    border: solid 1px $gray-light;
    border-radius: $border-radius;
    overflow: hidden;

    .img_container {
        height: 100%;
        width: 100%;
        max-width: 268px;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .text_container {
        color: $black;
        width: 100%;
        height: 100%;
        padding: 24px;
        background-color: $white;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        p:last-of-type {
            margin: 0;
        }
    }
}


.poem {
    padding: 24px;
    max-height: none;
    flex-direction: column;
    align-items: flex-start;

    .poem_tag {
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            height: 1.4rem;
            width: 1.3rem;
            margin-left: 4px;
            transition: $default-transition;
            background-image: url(../../../public/images/heart.svg);
            background-repeat: no-repeat;
            background-size: 0.8rem;
            background-position-y: center;
            position: absolute;
            top: -1px;
        }
    }


    p:last-of-type {
        margin: 0;
    }
}




@media only screen and (max-width: 900px) {
    .section_container {
        flex-direction: column;
        padding: $section-padding-mobile;
        gap: 52px;
    }

    .tall_card {
        max-width: none;

        .text_container {
            padding: 12px;
        }
    }

    .small_cards {
        max-width: 100%;
        gap: 52px;
    }

    .card {
        flex-direction: column;


        .img_container {
            max-width: none;
            max-height: 420px;
        }

        .text_container {
            padding: 12px;
            height: fit-content;
        }
    }

    .poem {
        padding: 12px;
    }
}