@use '../../utils/styles/variables' as *;

.container {
    width: 100%;
    background-color: $gray-dark;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.hearts_container {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100px;
    left: 6%;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 1000px;
        background-color: $gray-dark;
        top: 0;
        right: 42%;
    }
}

.hearts {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
    transform: scale(1.2);

    &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
        width: 100%;
    }

    &>div:first-of-type {
        transform: scale(1.5);
    }

    &>div:last-of-type {
        transform: scale(1.2);
        position: absolute;
        inset: 0;
    }
}

.background_banner {
    position: absolute;
    inset: 18px;
    background-color: $blue-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    z-index: 0;
}

.text {
    z-index: 1;
    font-family: 'Playfair Display', sans-serif;
    color: $white;
    text-align: center;
    margin: 38px;
    align-self: center;

    &>* {
        margin-bottom: 0;
    }
}

.svg_container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: middle;
    overflow: hidden;
}

.svg_content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}


@media only screen and (max-width: 900px) {
    .hearts {
        transform: scale(1.5);
    }

    .text {
        margin: 24px;
    }
}

@media only screen and (max-width: 370px) {
    .text {
        margin: 18px;
    }
}