@use './../../utils/styles/variables' as *;

.section_container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;

    h1 {
        white-space: nowrap;
        text-align: center;
    }

    span {
        height: 1px;
        width: 100%;
        border-radius: $border-radius;
        background-color: $black;
    }

    &>*:nth-child(1) {
        margin-right: 44px;
    }

    &>*:nth-child(3) {
        margin-left: 44px;
    }
}


@media only screen and (max-width: 900px) {
    .section_container {
        padding: $default-edge-padding;

        h1 {
            white-space: normal;
        }

        &>*:nth-child(1) {
            margin-right: 24px;
        }

        &>*:nth-child(3) {
            margin-left: 24px;
        }
    }
}