@use '../../utils/styles/variables' as *;

.container {
    width: 100%;
    height: 748px;
    display: flex;
    justify-content: center;
    align-items: center;
    isolation: isolate;
    position: relative;

    .outer_img_container {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: absolute;
        inset: 0;

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            background-color: $black-opacity;
        }

        img {
            height: 100%;
            width: 44%;
            object-fit: cover;
            filter: grayscale(100%);
            top: 0;
            position: absolute;
        }

        img:first-child {
            left: 0;
        }

        img:last-child {
            right: 0;
        }
    }
}

.constraints {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: $black;

    .meplus {
        position: absolute;
        height: 100px;
        width: 100px;
        right: 3%;
        top: 4%;
        z-index: 2;
    }

    .text {
        color: $white;
        padding: 32px;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .inner_img_container {
        height: 100%;
        width: 50%;
        z-index: 1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

}

@media only screen and (max-width: 900px) {
    .container {
        height: fit-content;
    }

    .constraints {
        flex-direction: column-reverse;

        .meplus {
            height: 60px;
            width: 60px;
            right: 6%;
        }

        .text {
            width: 100%;
            padding: 12px;
        }

        .inner_img_container {
            width: 100%;
        }
    }
}