@use './../../utils/styles/variables' as *;

.container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $white-opacity;
    z-index: 101;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;

    p {
        margin: 0;
        padding-right: 18px;
    }
}

.hidden {
    display: none;
}


@media only screen and (max-width: 900px) {
    .container {
        flex-direction: column;
    }
}