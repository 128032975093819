@use '../../utils/styles/variables' as *;

header {
    position: fixed;
    top: 0;
    min-height: 76px;
    width: 100%;
    z-index: 100;
    padding: 0 18px;
    transition: all 0.4s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    box-shadow: 0px 0px 4px $black-opacity;

    .constraints {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        height: 100%;

        ul {
            list-style-type: none;
            display: flex;
            gap: 12px;

            li {
                margin: 0;
            }

            li:last-child {
                margin: 0;
            }
        }

        .home {
            position: relative;
            width: 210px;

            img {
                object-fit: contain;
                width: 100%;
            }

            .meplus {
                position: absolute;
                height: 18px;
                width: 18px;
                right: -26px;
                top: 7px;
                z-index: 2;
            }
        }
    }
}


.nav {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 58px;
    left: -110px;
    transition: $default-transition;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $default-box-shadow;

    nav {
        display: flex;
        flex-direction: column;
        gap: 28px;
        padding: 18px;

        ul {
            flex-direction: column;
        }
    }
}


.nav_open {
    visibility: visible;
    opacity: 1;
    left: 0px;
}


.menu_btn_container {
    position: absolute;
    left: 0;
}


.menu_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
    border: none;
    appearance: none;
    background: none;
    position: relative;

    span {
        height: 4px;
        width: 100%;
        background-color: $white;
        border-radius: $border-radius;
        transition: $default-transition;
        align-self: center;
        position: absolute;
    }

    span:nth-child(1) {
        top: 6px;
    }

    span:nth-child(3) {
        bottom: 6px;
    }
}


.menu_btn_active {
    span:nth-child(1) {
        top: 14px;
        transform: rotate(45deg);
    }

    span:nth-child(2) {
        transform: rotate(-45deg);
    }

    span:nth-child(3) {
        bottom: 14px;
        transform: rotate(45deg);
    }
}

.nav_outer {
    position: absolute;
    left: 54px;

    ul {
        flex-direction: row;

        li {

            a {
                color: $white;

                &:before {
                    background-color: $white;
                }

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.outer_in {
    display: none;
}

@media only screen and (max-width: 900px) {
    header {
        padding: 0 6px;
    }

    .nav_outer {
        display: none;
    }

    .outer_in {
        display: block;
    }
}